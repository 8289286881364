import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import {
  RequestMoreInfo,
} from "@components/Forms"

const NotFoundPage = () => (
  <PageLayout>
    <SEO title="404 | Requested Page Not Found | Pressroom Alchemy" />
    <SingleColumnBlock
      textPadded
      text={
        <div>
          <h1>That there page you went lookin' for was not found.</h1>
          <p>We keep a tight ship here at Pressroom Alchemy. If that content were here, I'd know about it. You should try the <a href="/">homepage</a>.</p>
          <p>Or just get in touch and let us know what kind of custom print job you need.</p>
          <h4>Get a quote</h4>
          <RequestMoreInfo />
        </div>
      }
    />
  </PageLayout>
)

export default NotFoundPage
